var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      directives: [
        {
          name: "ripple",
          rawName: "v-ripple",
          value: _vm.isLinked,
          expression: "isLinked",
        },
      ],
      staticClass: "content-tile-card",
      attrs: { type: "button" },
      on: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
      },
    },
    [
      _vm.src
        ? _c("div", { staticClass: "content-tile-card-thumbnail" }, [
            _c("figure", { staticClass: "content-tile-card-thumbnail__img" }, [
              _c("img", { attrs: { src: _vm.src, alt: _vm.title } }),
            ]),
          ])
        : _vm._e(),
      _c("div", { staticClass: "content-tile-card-content" }, [
        _c("div", { staticClass: "content-tile-card-content__head" }, [
          _vm.title
            ? _c("p", { staticClass: "content-tile-card-content__title" }, [
                _vm._v(" " + _vm._s(_vm.title) + " "),
              ])
            : _vm._e(),
          _vm.subtitle
            ? _c("p", { staticClass: "content-tile-card-content__subtitle" }, [
                _vm._v(" " + _vm._s(_vm.subtitle) + " "),
              ])
            : _vm._e(),
        ]),
        _vm.tag
          ? _c(
              "div",
              { staticClass: "content-tile-card-content__tag" },
              [_c("Tag", { attrs: { tag: _vm.tag } })],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }